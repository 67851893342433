
import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";

import { CalibrationBlockCategory, CalibrationBlockItem } from "@/domain";

@Component
export default class CalibrationBlockUpdate extends Vue {
  @Prop({ required: true })
  item!: CalibrationBlockItem;

  @Prop({ required: true })
  forbiddenSerialNumbers!: {
    value: string;
    category: CalibrationBlockCategory;
  }[];

  get v1Component() {
    return () =>
      import(
        "@/components/Core/Update/Equipments/CalibrationBlocks/Children/V1BlockUpdate.vue"
      );
  }

  get v2Component() {
    return () =>
      import(
        "@/components/Core/Update/Equipments/CalibrationBlocks/Children/V2BlockUpdate.vue"
      );
  }

  get stepComponent() {
    return () =>
      import(
        "@/components/Core/Update/Equipments/CalibrationBlocks/Children/StepBlockUpdate.vue"
      );
  }

  get component() {
    if (this.item.blockCategory == CalibrationBlockCategory.v1) {
      return this.v1Component;
    } else if (this.item.blockCategory == CalibrationBlockCategory.v2) {
      return this.v2Component;
    } else {
      return this.stepComponent;
    }
  }

  get forbiddenSerialNumberFiltered() {
    return _.map(
      _.filter(
        this.forbiddenSerialNumbers,
        (f) => f.category == this.item.blockCategory
      ),
      (b) => b.value
    );
  }
}
